import '../style/Estilo.css';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import DadosCliente from './DadosCliente';
import DadosEvento from './DadosEvento';
import DadosPagamento from './DadosPagamento';
import Contratos from './Contratos';
import React, { useState, useEffect } from 'react';
import Carregando from '../imgs/carregando.gif';
import Image from 'react-bootstrap/Image';


function Navegacao({ dadosCliente, setDadosCliente, dadosEvento, setDadosEvento, dadosPagamento, setDadosPagamento, cpfOuCnpjType, setCpfOuCnpjType, contratos, setContratos, dataCW }) {

	// const dados = { data: { contact: { id: '9499' }, conversation: { id: '940' } } };

	const [data, setData] = useState(null); // Estado para armazenar a resposta da API
	const [error, setError] = useState(null); // Estado para armazenar erros, se houver

	useEffect(() => {
		// Função assíncrona para realizar a requisição
		const fetchData = async () => {
			try {
				const response = await fetch('https://enginewebhook.w29.unigate.com.br/webhook/consultar-campos', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						// Dados que você quer enviar no body da requisição POST
						contact_id: dataCW.data.contact.id,
						conversation_id: dataCW.data.conversation.id
					})
				});

				if (!response.ok) {
					throw new Error(`Erro na requisição: ${response.status}`);
				}

				const result = await response.json(); // Converte a resposta para JSON
				if (!result.data) {
					setData('sem')
					return
				}
				setData(result)

				setDadosCliente(result.dadosCliente)
				setDadosEvento(result.dadosEvento)
				setDadosPagamento(result.dadosPagamento)
				setCpfOuCnpjType(result.cpfOuCnpjType)

			} catch (err) {
				setError(err.message); // Armazena o erro no estado 'error' caso a requisição falhe
			}
		};

		fetchData(); // Chama a função para realizar a requisição

	}, []); // Executa apenas uma vez quando o componente é montado

	return (
		<div>
			{error && <p>Error: {error}</p>}

			{/* comentar para funcionar localmente */}
			{/* <div id='CarregandoContainer'> <Image id='Carregando' src={Carregando} /> </div>  */}
			{!data ? <div id='CarregandoContainer'> <Image id='Carregando' src={Carregando} /> </div> : (

				<Tabs defaultActiveKey="DadosCliente" className="mb-3" >
					<Tab eventKey="DadosCliente" title="Dados do Cliente">
						<DadosCliente
							dadosCliente={dadosCliente}
							setDadosCliente={setDadosCliente}
							cpfOuCnpjType={cpfOuCnpjType}
							setCpfOuCnpjType={setCpfOuCnpjType}
						/>
					</Tab>
					<Tab eventKey="DadosEvento" title="Dados do Evento">
						<DadosEvento
							dadosEvento={dadosEvento}
							setDadosEvento={setDadosEvento}
						/>
					</Tab>
					<Tab eventKey="DadosPagamento" title="Dados do Pagamento">
						<DadosPagamento
							dadosPagamento={dadosPagamento}
							setDadosPagamento={setDadosPagamento}
						/>
					</Tab>
					<Tab eventKey="Contratos" title="Contratos">
						<Contratos
							contratos={contratos}
							setContratos={setContratos}
							dataCW={dataCW}
						/> {/* Passa os contratos para o componente Contratos */}
					</Tab>
				</Tabs>
			)}
		</div >
	)
}

export default Navegacao;