import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';

function DadosPagamento({ dadosPagamento, setDadosPagamento, setDatasParcelas, setValoresParcelas, setFormasPagamentoParcelas, valoresParcelas, formasPagamentoParcelas, datasParcelas }) {
  const [parcelas, setParcelas] = useState(dadosPagamento.parcelas || 1);
  const [selecionados, setSelecionados] = useState([]);
  const [diferenca, setDiferenca] = useState(null);

  useEffect(() => {
    setSelecionados(dadosPagamento.forma || []);
  }, [dadosPagamento.forma]);

  useEffect(() => {
    if (dadosPagamento.detalhesParcelas) {
      setFormasPagamentoParcelas(
        dadosPagamento.detalhesParcelas.map((parcela) => parcela.formaPagamentoParcela || "")
      );
    }
  }, [dadosPagamento.detalhesParcelas, setFormasPagamentoParcelas]);

  // Funções para mudança de inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDadosPagamento(prevDadosPagamento => ({
      ...prevDadosPagamento,
      [name]: value
    }));
  };

  // Função para mudar o número de parcelas
  const handleParcelasChange = (e) => {
    const parcelasSelecionadas = Number(e.target.value);

    // Atualiza o número de parcelas
    setParcelas(parcelasSelecionadas);
    setDadosPagamento((prevDadosPagamento) => ({
      ...prevDadosPagamento,
      parcelas: parcelasSelecionadas,
    }));

    // Recalcular os valores das parcelas
    const valorParcelas = Array(parcelasSelecionadas).fill(
      (dadosPagamento.valor / parcelasSelecionadas).toFixed(2)
    );
    setValoresParcelas(valorParcelas);

    // Recalcular as formas de pagamento (mantendo existentes e preenchendo com vazio)
    const formasParcelas = Array(parcelasSelecionadas)
      .fill("")
      .map((_, index) => formasPagamentoParcelas[index] || "");
    setFormasPagamentoParcelas(formasParcelas);

    // Recalcular as datas (mantendo existentes e preenchendo com novas datas)
    const novasDatas = calcularDatasParcelas(dadosPagamento.data, parcelasSelecionadas);
    const datasParcelasAtualizadas = Array(parcelasSelecionadas)
      .fill("")
      .map((_, index) => datasParcelas[index] || novasDatas[index]);
    setDatasParcelas(datasParcelasAtualizadas);

    // Atualizar os detalhes de parcelas
    const detalhesAtualizados = Array(parcelasSelecionadas).fill({}).map((_, index) => ({
      parcela: index + 1,
      valorParcela: Number(valorParcelas[index]),
      formaPagamentoParcela: formasParcelas[index] || "",
      dataPagamentoParcela: datasParcelasAtualizadas[index] || "",
    }));

    setDadosPagamento((prevDadosPagamento) => ({
      ...prevDadosPagamento,
      detalhesParcelas: detalhesAtualizados,
    }));
    setDiferenca(null);
  };

  // Função para mudar o valor das parcelas
  const handleValorParcelaChange = (e, index) => {
    const updatedParcelasDados = [...valoresParcelas];

    updatedParcelasDados[index] = Number(e.target.value);
    setValoresParcelas(updatedParcelasDados);

    const updatedDetalhesParcelas = [...dadosPagamento.detalhesParcelas];
    updatedDetalhesParcelas[index] = {
      ...updatedDetalhesParcelas[index],
      valorParcela: Number(e.target.value)
    };
    setDadosPagamento(prevDadosPagamento => ({
      ...prevDadosPagamento,
      detalhesParcelas: updatedDetalhesParcelas
    }));

    verificarDiferenca(updatedParcelasDados);
  };

  const formasPagamentoOpcoes = [
    { id: "Pix", label: "Pix" },
    { id: "Cartão de débito", label: "Cartão de débito" },
    { id: "Cartão de crédito", label: "Cartão de crédito" },
    { id: "Boleto", label: "Boleto" }
  ];

  // Função para mudar a forma de pagamento para cada parcela
  const handleFormaPagamentoParcelaChange = (e, index) => {
    const { value } = e.target;
    const novasFormasPagamentoParcelas = [...formasPagamentoParcelas];
    novasFormasPagamentoParcelas[index] = value;
    setFormasPagamentoParcelas(novasFormasPagamentoParcelas);

    const updatedFormasPagamentoParcelas = [...dadosPagamento.detalhesParcelas];
    updatedFormasPagamentoParcelas[index] = {
      ...updatedFormasPagamentoParcelas[index],
      formaPagamentoParcela: value
    };
    setDadosPagamento(prevDadosPagamento => ({
      ...prevDadosPagamento,
      detalhesParcelas: updatedFormasPagamentoParcelas
    }));
  };

  // Função para mudar a data de pagamento das parcelas
  const handleDataParcelaChange = (e, index) => {
    const novaData = e.target.value;

    // Atualiza a data da parcela no estado
    const novasDatas = [...datasParcelas];
    novasDatas[index] = novaData;
    setDatasParcelas(novasDatas);

    // Atualiza o detalhe da parcela com a nova data
    const updatedDetalhesParcelas = [...dadosPagamento.detalhesParcelas];
    updatedDetalhesParcelas[index] = {
      ...updatedDetalhesParcelas[index],
      dataPagamentoParcela: novaData
    };

    setDadosPagamento(prevDadosPagamento => ({
      ...prevDadosPagamento,
      detalhesParcelas: updatedDetalhesParcelas
    }));
  };

  const handleChange = (id) => {
    if (selecionados.includes(id)) {
      // Desmarca o checkbox se já está selecionado
      const atualizados = selecionados.filter((item) => item !== id);
      setSelecionados(atualizados);
      setDadosPagamento((prev) => ({
        ...prev,
        forma: atualizados, // Atualiza o campo forma em dadosPagamento
      }));
    } else if (selecionados.length < 2) {
      // Marca o checkbox se ainda há espaço
      const novosSelecionados = [...selecionados, id];
      setSelecionados(novosSelecionados);
      setDadosPagamento((prev) => ({
        ...prev,
        forma: novosSelecionados, // Atualiza o campo forma em dadosPagamento
      }));
    }
  };

  useEffect(() => {
    // Salvar dadosPagamento no localStorage
    localStorage.setItem('dadosPagamento', JSON.stringify(dadosPagamento));
  }, [dadosPagamento]);  // Salva sempre que dadosPagamento mudar

  useEffect(() => {
    const dadosSalvos = localStorage.getItem('dadosPagamento');
    if (dadosSalvos) {
      setDadosPagamento(JSON.parse(dadosSalvos));  // Carrega os dados salvos
    }
  }, []);  // Executa apenas uma vez ao montar o componente

  const handleValorTotalChange = () => {
    const numParcelas = Number(dadosPagamento.parcelas) || 1;
    const novoValorParcelas = Array(numParcelas).fill((dadosPagamento.valor / numParcelas).toFixed(2));
    setValoresParcelas(novoValorParcelas);

    const detalhesAtualizados = novoValorParcelas.map((valor, index) => ({
      ...dadosPagamento.detalhesParcelas[index],
      valorParcela: Number(valor),
    }));
    setDadosPagamento(prevDadosPagamento => ({
      ...prevDadosPagamento,
      detalhesParcelas: detalhesAtualizados,
    }));
    setDiferenca(null);
  };

  useEffect(() => {
    handleValorTotalChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dadosPagamento.valor]);


  const calcularDatasParcelas = (dataInicial, numParcelas) => {
    const datas = [];
    let dataAtual = new Date(dataInicial);  // A data inicial é definida aqui
    for (let i = 0; i < numParcelas; i++) {
      const novaData = new Date(dataAtual);
      novaData.setMonth(dataAtual.getMonth() + i);  // Ajusta para o próximo mês
      datas.push(formatarData(novaData));  // Armazena a data formatada
    }
    return datas;
  };

  const formatarData = (data) => {
    const dateObj = new Date(data);
    const dia = String(dateObj.getDate() + 1).padStart(2, '0');
    const mes = String(dateObj.getMonth() + 1).padStart(2, '0');
    const ano = dateObj.getFullYear();
    return `${ano}-${mes}-${dia}`;
  };

  useEffect(() => {
    // Calculando as novas datas a partir da data inicial
    const novasDatas = calcularDatasParcelas(dadosPagamento.data, dadosPagamento.parcelas);
    setDatasParcelas(novasDatas);
  }, [dadosPagamento.data, dadosPagamento.parcelas]);  // Dependências atualizadas

  // || [valoresParcelas, formasPagamentoParcelas, datasParcelas, dadosPagamento]
  useEffect(() => {
    handleValorTotalChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dadosPagamento.valor])

  const verificarDiferenca = (valores) => {
    const somaParcelas = valores.reduce((acc, val) => acc + parseFloat(val || 0), 0);
    const valorTotal = parseFloat(dadosPagamento.valor || 0);

    if (somaParcelas === valorTotal) {
      setDiferenca(null);
    } else if (somaParcelas > valorTotal) {
      setDiferenca(`A soma das parcelas é maior que o valor total por R$${(somaParcelas - valorTotal).toFixed(2)}`);
    } else {
      setDiferenca(`A soma das parcelas é menor que o valor total por R$${(valorTotal - somaParcelas).toFixed(2)}`);
    }
  };

  return (
    <div style={{ width: '90%', marginRight: 'auto', marginLeft: 'auto' }}>
      <h1 id="Titulo">Dados do Pagamento</h1>
      <Form id="Form">
        <div>
          <div className="LinhaForm">
            <Form.Group style={{ width: "100%" }} className="CamposPagamento">
              <FloatingLabel controlId="floatingValor" label="Valor">
                <Form.Control
                  type="text"
                  placeholder="Valor"
                  value={dadosPagamento.valor}
                  name="valor"
                  onChange={(e) => { handleInputChange(e); handleValorTotalChange() }}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group style={{ width: "100%" }} className="CamposPagamento">
              <FloatingLabel controlId="floatingDataPagamento" label="Data de pagamento">
                <Form.Control
                  type="date"
                  placeholder="Data de pagamento"
                  value={dadosPagamento.data}
                  name="data"
                  onChange={handleInputChange}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group style={{ width: "100%" }} className="CamposPagamento">
              <FloatingLabel label="Parcelas">
                <Form.Select
                  aria-label="Parcelas"
                  value={dadosPagamento.parcelas}
                  name="parcelas"
                  onChange={handleParcelasChange}
                >
                  {[...Array(10).keys()].map(i => (
                    <option key={i + 1} value={i + 1}>{i + 1}</option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          </div>

          <div id="FormaPagamento" >
            <p style={{ marginRight: '15px', color: 'azure' }}>Formas de pagamento: </p>

            {formasPagamentoOpcoes.map((opcao) => (
              <Form.Check
                inline
                key={opcao.id}
                label={opcao.label}
                type="checkbox"
                id={opcao.id}
                checked={selecionados.includes(opcao.id)}
                onChange={() => handleChange(opcao.id)}
                disabled={!selecionados.includes(opcao.id) && selecionados.length >= 2}
              />
            ))}

          </div>

          {diferenca &&
            <p style={{ color: 'azure', display: 'flex', justifySelf: 'center' }}>
              <i class="bi bi-exclamation-circle" style={{ marginRight: '5px' }}></i> {diferenca}
            </p>
          }

          {dadosPagamento.parcelas > 1 ? (
            <Table className='mt-3 TabelaPagamento'>
              <thead>
                <tr>
                  <th>Parcela</th>
                  <th>Valor</th>
                  <th>Forma de pagamento</th>
                  <th>Data</th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: Number(dadosPagamento.parcelas) }).map((value, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <Form.Control
                        type="number"
                        value={valoresParcelas[index] || ''}
                        onChange={(e) => handleValorParcelaChange(e, index)}
                      />
                    </td>
                    <td>
                      <Form.Select
                        value={formasPagamentoParcelas[index] || ''}
                        onChange={(e) => handleFormaPagamentoParcelaChange(e, index)}
                      >
                        <option value="" hidden>Selecione</option>
                        {selecionados.map((id) => {
                          const opcao = formasPagamentoOpcoes.find((o) => o.id === id);
                          return (
                            <option key={opcao.id} value={opcao.id}>
                              {opcao.label}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Control
                        type="date"
                        value={datasParcelas[index] || ''}
                        onChange={(e) => handleDataParcelaChange(e, index)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : null}

        </div>
      </Form>
    </div>
  );
}

export default DadosPagamento;