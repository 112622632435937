import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from "react-bootstrap/FloatingLabel";

function DadosEvento({ dadosEvento, setDadosEvento }) {
  const handleChange = (e) => {
    let { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setDadosEvento({
        ...dadosEvento,
        [name]: checked
      });
      return
    }
    setDadosEvento({
      ...dadosEvento,
      [name]: value
    });
  };

  return (
    <div className='container'>
      <h1 id="Titulo" className="text-center">Dados do Evento</h1>
      <Form id="Form" className='FormEvento'>
        <div className="LinhaForm">
          <div className="ColunaForm" >
            <Form.Group className="mb-3 CamposEvento">
              <FloatingLabel controlId="floatingInput" label="Tema da decoração">
                <Form.Control type="text" placeholder="Tema da decoração"  onChange={handleChange} value={dadosEvento.temaDecoracao} name='temaDecoracao' />
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3 CamposEvento">
              <FloatingLabel controlId="floatingNomeAniversariante" label="Nome aniversariante" >
                <Form.Control type="text" placeholder="Nome do aniversariante" onChange={handleChange} value={dadosEvento.nomeAniversariante} name='nomeAniversariante' />
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3 CamposEvento">
              <FloatingLabel controlId="floatingIdade" label="Idade a comemorar">
                <Form.Control type="text" placeholder="Idade a comemorar" onChange={handleChange} value={dadosEvento.idadeComemorar} name='idadeComemorar' />
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3 CamposEvento">
              <FloatingLabel label="Pacote">
                <Form.Select
                  aria-label="Pacote"
                  value={dadosEvento.pacote}
                  name='pacote'
                  onChange={(e) => {
                    handleChange(e);
                    if (e.target.value) {
                      e.target.options[0].style.display = "none";
                    }
                  }}
                >
                  <option value="">Selecione um pacote</option>
                  <option value="Coquetel">Coquetel</option>
                  <option value="Gold">Gold</option>
                  <option value="Gold + Feijoada">Gold + Feijoada</option>
                  <option value="Escolar">Escolar</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3 CamposEvento">
              <FloatingLabel controlId="floatingDataNascimento1" label="Data de nascimento">
                <Form.Control type="date" placeholder="Data de nascimento" onChange={handleChange} value={dadosEvento.dataNascimento} name='dataNascimento' />
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3 CamposEvento">
              <FloatingLabel controlId="floatingDataNascimento2" label="Data da festa">
                <Form.Control type="date" placeholder="Data da festa"  onChange={handleChange} value={dadosEvento.dataFesta} name='dataFesta' />
              </FloatingLabel>
            </Form.Group>
          </div>

          {/* Segunda coluna de dados do evento */}
          <div className="ColunaForm">
            <Form.Group className="mb-3 CamposEvento">
              <FloatingLabel controlId="floatingHorarioInicio" label="Horário de início" >
                <Form.Control type="text" placeholder="Horário de início" onChange={handleChange} value={dadosEvento.horarioInicio} name='horarioInicio' />
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3 CamposEvento">
              <FloatingLabel controlId="floatingHorarioFim" label="Horário de fim" >
                <Form.Control type="text" placeholder="Horário de fim" onChange={handleChange} value={dadosEvento.horarioFim} name='horarioFim' />
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3 CamposEvento">
              <FloatingLabel controlId="floatingNumeroConvidados" label="Número convidados" >
                <Form.Control type="text" placeholder="Número de convidados" onChange={handleChange} value={dadosEvento.numConvidados} name='numConvidados' />
              </FloatingLabel>
            </Form.Group>

            <Form.Group>
              <Form.Check
							  custom
								id="nenhumChopp"
								type="radio"
								label="Nenhum Chopp"
								name="Chopp"
								style={{ color: "azure" }}
							/>
						</Form.Group>

            <Form.Group>
              <Form.Check
							  custom
								id="choppHeineken"
								type="radio"
                defaultChecked
								label="Chopp de Heineken"
								name="Chopp"
								style={{ color: "azure" }}
							/>
						</Form.Group>

            <Form.Group>
              <Form.Check
							  custom
								id="choppBrahma"
								type="radio"
								label="Chopp de Brahma"
								name="Chopp"
								style={{ color: "azure" }}
							/>
						</Form.Group>

            <Form.Group className="mb-3">
              <Form.Check
                id="TorreBaloes"
                type="checkbox"
                label="Torre de Balões"
                name="torreBaloes"
                checked={dadosEvento.torreBaloes}
                style={{ color: 'azure' }}
                onChange={handleChange}
              />
            </Form.Group>

            {dadosEvento.torreBaloes && (
              <Form.Group className="mb-3 CamposEvento">
                <FloatingLabel controlId="floatingIdade" label="Descrição balões">
                  <Form.Control
                    type="text"
                    placeholder="Descrição breve balões"
                    onChange={handleChange}
                    value={dadosEvento.descBaloes}
                    name="descBaloes"
                  />
                </FloatingLabel>
              </Form.Group>
            )}

           

          </div>
        </div>
        <div className="LinhaForm" style={{ marginLeft: "7px", marginRight: "7px", width: "100%" }}>
           <Form.Group style={{ width: "98%" }} className="mb-3 CamposEvento">
              <FloatingLabel controlId="floatingHorarioInicio" label="Adicionais">
                <Form.Control 
                style={{ height: "110px", width: "100%" }} 
                as="textarea"
                placeholder="Adicionais" 
                onChange={handleChange} 
                value={dadosEvento.adicionais} 
                name='adicionais' />
              </FloatingLabel>
            </Form.Group>
        </div>
      </Form>
    </div>
  );
}

export default DadosEvento;