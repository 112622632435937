import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Logo from '../imgs/uni-atende.png';
import LogoPepes from '../imgs/logo-pepes-park-buffet.png';
import React, { useState } from 'react';

function Cabecalho({ dados, atualizarContratos, dataCW }) {
	const [statusBotao, setStatusBotao] = useState(false)
	const [botaoTexto, setBotaoTexto] = useState("Guardar dados");
	const [error, setError] = useState(null); // Estado para armazenar erros, se houver
	// const dadosCW = { data: { contact: { id: '9499' }, conversation: { id: '940' } } };

	const salvar = () => {
		setStatusBotao(true);
		setBotaoTexto("Salvando...");
		console.log(dataCW)
		console.log(dataCW.data)
		console.log(dataCW.data.contact)
		const fetchData = async () => {
			try {
				const response = await fetch('https://enginewebhook.w29.unigate.com.br/webhook/salvar-contrato', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ dados, contact_id: dataCW.data.contact.id, conversation_id: dataCW.data.conversation.id })
				});

				if (!response.ok) {
					throw new Error(`Erro na requisição: ${response.status}`);
				}

				setBotaoTexto("Salvou!");

				setTimeout(() => {
					setBotaoTexto("Guardar dados");
					setStatusBotao(false);
				}, 2000);

				// const result = await response.json(); // Converte a resposta para JSON
				// setStatusBotao(false)

			} catch (err) {
				setError('cabec42' + err.message); // Armazena o erro no estado 'error' caso a requisição falhe
				setStatusBotao(false);
				setBotaoTexto("Guardar dados");
			}
		};
		fetchData(); // Chama a função para realizar a requisição
	}

	const criar = () => {
		setStatusBotao(true)
		const fetchData = async () => {
			try {
				const response = await fetch('https://enginewebhook.w29.unigate.com.br/webhook/criar-contrato', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ dados, contact_id: dataCW.data.contact.id, conversation_id: dataCW.data.conversation.id })
				});

				if (!response.ok) {
					throw new Error(`Erro na requisição: ${response.status}`);
				}

				atualizarContratos();

				// const result = await response.json(); // Converte a resposta para JSON
				setStatusBotao(false)

			} catch (err) {
				setError('cabec72' + err.message); // Armazena o erro no estado 'error' caso a requisição falhe
			}
		};
		fetchData(); // Chama a função para realizar a requisição
	}

	// const [isVisible, setIsVisible] = useState(false);

	// const showParagraph = () => {
	// 	setIsVisible(true);
	// 	setTimeout(() => setIsVisible(false), 3000);
	// };


	return (
		<div>
			<div id='Marca'>
				<div>
					<Image id='Logo' src={LogoPepes} />
				</div>

				<div>
					<h1 id="PepeTitulo" style={{ color: '#02B890', fontWeight: 'bold' }}>Pepe's Park Buffet</h1>
				</div>
			</div>

			<div style={{ display: "flex", flexDirection: "row", marginTop: "10px", marginBottom: "10px" }}>

				{error && <p>Error: {error}</p>}
				<div id="caixaLogoUni">
					<Image id='LogoUni' src={Logo} />
				</div>

				<div className="BtnContrato">
					<Button
						variant='secondary'
						disabled={statusBotao}
						className='custom-btn'
						onClick={() => { salvar(); }}
					>
						{botaoTexto} <i className="bi bi-floppy"></i>
					</Button>
				</div>

				<div className="BtnContrato">
					<Button
						variant='secondary'
						disabled={statusBotao}
						className='custom-btn'
						onClick={() => { criar(); salvar() }}
					>
						Criar contrato <i className="bi bi-file-earmark-plus"></i>
					</Button>
				</div>
				<div className="BtnContrato" style={{ marginTop: "10px"}}>
					<h5>Vendedor(a): andrea</h5>

				</div>

			</div>
		</div>

	)
}

export default Cabecalho;