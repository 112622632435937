import React from 'react';
import ReactDOM from 'react-dom/client'; // Importa de 'react-dom/client' no React 18
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';

// Cria uma instância do QueryClient
const queryClient = new QueryClient();

// Usa o createRoot para renderizar o aplicativo
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
);
