import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/Estilo.css';
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import Form from 'react-bootstrap/Form';
import React, { useState } from 'react';

function DadosCliente({ dadosCliente, setDadosCliente, cpfOuCnpjType, setCpfOuCnpjType }) {

	const [error, setError] = useState(null);

	// Função genérica para atualizar os campos
	const handleChange = (e) => {
		const { name, value } = e.target;

		// Checa se o campo faz parte do objeto 'endereco'
		if (dadosCliente.endereco && name in dadosCliente.endereco) {
			setDadosCliente({
				...dadosCliente,
				endereco: {
					...dadosCliente.endereco,
					[name]: value
				}
			});
		} else {
			setDadosCliente({
				...dadosCliente,
				[name]: value
			});
		}
	};

	// Função para atualizar o tipo CPF ou CNPJ e limpar o campo para nova entrada
	const handleCpfCnpjTypeChange = () => {
		if (document.getElementById("cpf").checked === true) {
			setCpfOuCnpjType('cpf');
			console.log(cpfOuCnpjType);
		} else if (document.getElementById("cnpj").checked === true) {
			setCpfOuCnpjType('cnpj');
		}
	};

	// Função para buscar o CEP na API ViaCEP
	const buscarCep = async () => {
		if (dadosCliente.endereco.cep === undefined) return;

		const cep = dadosCliente.endereco.cep;
		if (cep.length !== 8) {
			setError("Por favor, insira um CEP válido com 8 dígitos.");
			return;
		}

		try {
			const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
			const data = await response.json();

			if (data.erro) {
				setError("CEP não encontrado.");
				setDadosCliente({
					...dadosCliente,
					endereco: {
						cep,
						estado: '',
						cidade: '',
						logradouro: '',
						bairro: '',
						numero: '',
						complemento: ''
					}
				});
			} else {
				setDadosCliente({
					...dadosCliente,
					endereco: {
						...dadosCliente.endereco,
						estado: data.estado,
						cidade: data.localidade,
						logradouro: data.logradouro,
						bairro: data.bairro
					}
				});
				setError(null);
			}
		} catch (err) {
			setError("Erro ao buscar o CEP. Tente novamente mais tarde.");
		}
	};

	return (
		<div className='container'>
			<h1 id="Titulo">Dados do Cliente</h1>

			<Form id="Form">
				<div className="LinhaForm">
					<div className="ColunaForm" style={{ width: "50%" }}>
						<Form.Group>
							<FloatingLabel label="Nome completo">
								<Form.Control
									type="text"
									placeholder="Nome completo"
									onChange={handleChange}
									value={dadosCliente.nome}
									name='nome'
								/>
							</FloatingLabel>
						</Form.Group>
					</div>

					<div className="ColunaForm" style={{ width: "50%" }}>
						<Form.Group>
							<FloatingLabel label="E-mail">
								<Form.Control
									type="email"
									placeholder="Endereço de e-mail"
									onChange={handleChange}
									value={dadosCliente.email}
									name='email'
								/>
							</FloatingLabel>
						</Form.Group>
					</div>
				</div>

				<div className="LinhaForm">
					<div className="ColunaForm" style={{ width: "40%" }}>
						<Form.Group>
							<FloatingLabel label="Telefone">
								<Form.Control
									type="tel"
									placeholder="Telefone"
									onChange={handleChange}
									value={dadosCliente.telefone}
									name='telefone'
								/>
							</FloatingLabel>
						</Form.Group>
					</div>

					<div className="ColunaForm" style={{ width: "45%" }}>
						<Form.Group>
							<FloatingLabel label={cpfOuCnpjType === "cnpj" ? "CNPJ" : "CPF"}>
								<Form.Control
									type="text"
									placeholder="CPF / CNPJ"
									onChange={handleChange}
									value={dadosCliente.cpfOuCnpj}
									name="cpfOuCnpj"
								/>
							</FloatingLabel>
						</Form.Group>
					</div>

					<div className="ColunaForm" style={{ width: "15%" }}>
						<Form.Group>
							<Form.Check
								id="cpf"
								type="radio"
								label="CPF"
								name="cpfOuCnpj"
								style={{ color: "azure" }}
								onChange={() => handleCpfCnpjTypeChange()}
								defaultChecked={cpfOuCnpjType === 'cpf'}
							/>
							<Form.Check
								id="cnpj"
								type="radio"
								label="CNPJ"
								name="cpfOuCnpj"
								style={{ color: "azure" }}
								onChange={() => handleCpfCnpjTypeChange()}
								defaultChecked={cpfOuCnpjType === 'cnpj'}
							/>
						</Form.Group>
					</div>
				</div>

				<h3 id="SubTitulo">Endereço</h3>

				<div className="LinhaForm">
					<div className="ColunaForm" style={{ width: "30%" }}>
						<Form.Group>
							<FloatingLabel label="CEP">
								<Form.Control
									type="text"
									placeholder="CEP"
									onChange={handleChange}
									onBlur={buscarCep}
									value={dadosCliente.endereco.cep}
									name='cep'
								/>
							</FloatingLabel>
						</Form.Group>
					</div>

					<div className="ColunaForm" style={{ width: "30%" }}>
						<Form.Group>
							<FloatingLabel label="Estado">
								<Form.Control
									className="CamposCliente"
									type="text"
									placeholder="Estado"
									value={dadosCliente.endereco.estado}
									name='estado'
									readOnly
								/>
							</FloatingLabel>
						</Form.Group>
					</div>

					<div className="ColunaForm" style={{ width: "40%" }}>
						<Form.Group>
							<FloatingLabel label="Cidade">
								<Form.Control
									className="CamposCliente"
									type="text"
									placeholder="Cidade"
									value={dadosCliente.endereco.cidade}
									name='cidade'
									readOnly
								/>
							</FloatingLabel>
						</Form.Group>
					</div>
				</div>

				<div className="LinhaForm">
					<div className="ColunaForm" style={{ width: "50%" }}>
						<Form.Group>
							<FloatingLabel label="Logradouro">
								<Form.Control
									className="CamposCliente"
									type="text"
									placeholder="Logradouro"
									onChange={handleChange}
									value={dadosCliente.endereco.logradouro}
									name='logradouro'
								/>
							</FloatingLabel>
						</Form.Group>
					</div>

					<div className="ColunaForm" style={{ width: "50%" }}>
						<Form.Group>
							<FloatingLabel label="Bairro">
								<Form.Control
									className="CamposCliente"
									type="text"
									placeholder="Bairro"
									onChange={handleChange}
									value={dadosCliente.endereco.bairro}
									name='bairro'
								/>
							</FloatingLabel>
						</Form.Group>
					</div>
				</div>

				<div className="LinhaForm">
					<div className="ColunaForm" style={{ width: "50%" }}>
						<Form.Group>
							<FloatingLabel label="Número">
								<Form.Control
									type="text"
									placeholder="Número"
									onChange={handleChange}
									value={dadosCliente.endereco.numero}
									name='numero'
								/>
							</FloatingLabel>
						</Form.Group>
					</div>

					<div className="ColunaForm" style={{ width: "50%" }}>
						<Form.Group>
							<FloatingLabel label="Complemento">
								<Form.Control
									type="text"
									placeholder="Complemento"
									onChange={handleChange}
									value={dadosCliente.endereco.complemento}
									name='complemento'
								/>
							</FloatingLabel>
						</Form.Group>
					</div>
				</div>

				<div className="LinhaForm">
					<div className="ColunaForm" style={{ width: "100%" }}>
						<Form.Group className="h-50">
							<FloatingLabel label="Como nos conheceu?">
								<Form.Control
									as="textarea"
									placeholder="Como nos conheceu?"
									name='formaConhecimento'
									onChange={handleChange}
									value={dadosCliente.formaConhecimento}
									style={{ height: "110px", marginTop: "3%" }} />
							</FloatingLabel>
						</Form.Group>
					</div>
				</div>

				{error && <p style={{ color: 'azure' }}>{error}</p>}
			</Form>
		</div>

	);
}

export default DadosCliente;
