import 'bootstrap/dist/css/bootstrap.min.css';
import Navegacao from './Navegacao';
import Cabecalho from './Cabecalho';
import React, { useState, useEffect, useRef } from 'react';
// let messageListenerAdded = false; // Variável global para controlar o registro

function FormContrato() {
	// const dados = { data: { contact: { id: '9499' }, conversation: { id: '940' } } };
	const [dataCW, setDataCW] = useState(null);
	const listenerAdded = useRef(false);  // Usar useRef para persistir o estado

	const [dadosCliente, setDadosCliente] = useState({
		nome: '',
		email: '',
		telefone: '',
		cpfOuCnpj: '',
		endereco: {
			cep: '',
			estado: '',
			cidade: '',
			logradouro: '',
			bairro: '',
			numero: '',
			complemento: ''
		},
		formaConhecimento: ''
	});

	const [dadosEvento, setDadosEvento] = useState({
		temaDecoracao: '',
		nomeAniversariante: '',
		idadeComemorar: 0,
		dataNascimento: '',
		dataFesta: '',
		horarioInicio: '',
		horarioFim: '',
		pacote: '',
		numConvidados: 0,
		// chopp: qq coloca aq,
		torreBaloes: true,
		descBaloes: '',
		adicionais: ''
	});

	const [dadosPagamento, setDadosPagamento] = useState({
		forma: '',
		valor: '',
		data: ''
	});

	const [cpfOuCnpjType, setCpfOuCnpjType] = useState('');
	const [contratos, setContratos] = useState([]);
	const [error, setError] = useState(null);

	useEffect(() => {	
		const handleMessage = (event) => {
			if (event.origin === 'https://w29.unigate.com.br') {
				try {
					const data = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
		
					if (data && data.data) {
						// Verifica se os dados são diferentes antes de atualizar o estado
						if (JSON.stringify(data) !== JSON.stringify(dataCW)) {
							setDataCW(data);
						}
					} else {
						setDataCW(null);
						setError("Erro: reinicia a página.");
					}
				} catch (error) {
					console.error('Erro ao analisar os dados:', error);
					setDataCW(null);
				}
			} else {
				// setError("Reinicie a página.");
				console.log('Mensagem recebida de origem não esperada:', event.origin);
			}
		};
		
	
		// Verifique se o listener já foi adicionado
		if (!listenerAdded.current) {
			console.log("Listener adicionado");
			window.addEventListener('message', handleMessage);
			listenerAdded.current = true;
		}
	
		return () => {
			if (listenerAdded.current) {
				window.removeEventListener('message', handleMessage);
				listenerAdded.current = false;
			}
		};
	}, []); // Executa apenas uma vez

	const atualizarContratos = async () => {
		try {
			const response = await fetch('https://enginewebhook.w29.unigate.com.br/webhook/consultar-contratos', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					contact_id: dataCW.data.contact.id,
					conversation_id: dataCW.data.conversation.id
				})
			});

			if (!response.ok) {
				throw new Error(`Erro na requisição: ${response.status}`);
			}

			const result = await response.json(); // Converte a resposta para JSON
			setContratos(result); // Atualiza o estado dos contratos

		} catch (err) {
			setError('formcontrat' + err.message); // Armazena o erro no estado 'error' caso a requisição falhe
		}
	};

	return (
		<div id='Fundinho'>
			{error && !dataCW && <p>Error: {error}</p>}
			{/* {<Cabecalho dados={[dadosCliente, dadosEvento, dadosPagamento, cpfOuCnpjType]} atualizarContratos={atualizarContratos} />}
			{<Navegacao dadosCliente={dadosCliente} setDadosCliente={setDadosCliente} dadosEvento={dadosEvento} setDadosEvento={setDadosEvento} dadosPagamento={dadosPagamento} setDadosPagamento={setDadosPagamento} cpfOuCnpjType={cpfOuCnpjType} setCpfOuCnpjType={setCpfOuCnpjType} contratos={contratos} setContratos={setContratos}/>} */}
			{dataCW && <Cabecalho dados={[dadosCliente, dadosEvento, dadosPagamento, cpfOuCnpjType]} atualizarContratos={atualizarContratos} dataCW={dataCW} />}
			{dataCW && <Navegacao dadosCliente={dadosCliente} setDadosCliente={setDadosCliente} dadosEvento={dadosEvento} setDadosEvento={setDadosEvento} dadosPagamento={dadosPagamento} setDadosPagamento={setDadosPagamento} cpfOuCnpjType={cpfOuCnpjType} setCpfOuCnpjType={setCpfOuCnpjType} contratos={contratos} setContratos={setContratos} dataCW={dataCW} />}
		</div>
	);
}

export default FormContrato;
