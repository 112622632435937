import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from 'react-bootstrap/Table';

function Contratos({ contratos, setContratos, dataCW, dadosEvento, chopp, dadosPagamento }) {
  // const dados = { data: { contact: { id: '9499' }, conversation: { id: '940' } } };

  // const [data, setData] = useState(null); // Estado para armazenar a resposta da API
  const [error, setError] = useState(null); // Estado para armazenar erros, se houver

  useEffect(() => {
    // Função assíncrona para realizar a requisição
    const fetchData = async () => {
      try {
        const response = await fetch('https://enginewebhook.w29.unigate.com.br/webhook/consultar-contratos', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            // Dados que você quer enviar no body da requisição POST
            contact_id: dataCW.data.contact.id,
            conversation_id: dataCW.data.conversation.id
          })
        });

        if (!response.ok) {
          throw new Error(`Erro na requisição: ${response.message}`);
        }

        const result = await response.json(); // Converte a resposta para JSON

        setContratos(result);

      } catch (err) {
        setError(err.message); // Armazena o erro no estado 'error' caso a requisição falhe
      }
    };

    fetchData(); // Chama a função para realizar a requisição

  }, []); // Executa apenas uma vez quando o componente é montado

  // Função para abrir o PDF em uma nova aba
  const visualizarPDF = (index) => {
    let contratoEscolhido = contratos[index]
    const dadosContrato = {
      // do cliente
      nomeCliente: contratoEscolhido.dados_cliente.nome,
      cpfOuCnpj: contratoEscolhido.dados_cliente.cpfOuCnpj,
      tel: contratoEscolhido.dados_cliente.telefone,
      email: contratoEscolhido.dados_cliente.email,
      cep: contratoEscolhido.dados_cliente.endereco.cep,
      estado: contratoEscolhido.dados_cliente.endereco.estado,
      cidade: contratoEscolhido.dados_cliente.endereco.cidade,
      logradouro: contratoEscolhido.dados_cliente.endereco.logradouro,
      bairro: contratoEscolhido.dados_cliente.endereco.bairro,
      numEndereco: contratoEscolhido.dados_cliente.endereco.numero,
      complemento: contratoEscolhido.dados_cliente.endereco.complemento,
      comoConheceu: contratoEscolhido.dados_cliente.formaConhecimento,
      // do evento
      temaDecoracao: contratoEscolhido.dados_evento.temaDecoracao,
      nomeAniversariante: contratoEscolhido.dados_evento.nomeAniversariante,
      idadeComemorar: contratoEscolhido.dados_evento.idadeComemorar,
      dataNascimento: contratoEscolhido.dados_evento.dataNascimento,
      dataFesta: contratoEscolhido.dados_evento.dataFesta,
      horarioInicio: contratoEscolhido.dados_evento.horarioInicio,
      horarioFim: contratoEscolhido.dados_evento.horarioFim,
      pacote: contratoEscolhido.dados_evento.pacote,
      numConvidados: contratoEscolhido.dados_evento.numConvidados,
      chopp: chopp,
      torreBaloes: contratoEscolhido.dados_evento.torreBaloes,
      descTorreBaloes: contratoEscolhido.dados_evento.descBaloes,
      adicionais: dadosEvento.adicionais,
      // do pagamento
      valor: dadosPagamento.valor,
      parcelas: dadosPagamento.parcelas,
      data: dadosPagamento.data,
      forma: dadosPagamento.forma,
      detalhesParcelas: dadosPagamento.detalhesParcelas.map((parcela) => ({
        parcela: parcela.parcela,
        valorParcela: parcela.valorParcela,
        formaPagamentoParcela: parcela.formaPagamentoParcela,
        dataPagamentoParcela: parcela.dataPagamentoParcela,
      })),
      // do contrato
      dataContrato: contratoEscolhido.data,
      vendedor: dataCW.data.currentAgent.name
    }

    const urlPDF = `/src/ContratoPDF.html?contratoId=${contratoEscolhido.id}`;
    const pdf = window.open(urlPDF, "_blank");
    pdf.onload = () => {
      pdf.postMessage(dadosContrato, '*');
    }
  };

  const excluir = (index) => {

    const contratoParaExcluir = contratos[index]; // Armazena o contrato a ser excluído
    setContratos((prevContratos) =>
      prevContratos.filter((_, i) => i !== index)
    );
    const fetchData = async () => {
      let idContrato = contratos[index].id
      try {
        const response = await fetch('https://enginewebhook.w29.unigate.com.br/webhook/excluir-contrato', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            // Dados que você quer enviar no body da requisição POST
            contact_id: dataCW.data.contact.id,
            conversation_id: dataCW.data.conversation.id,
            idContrato
          })
        });

        if (!response.ok) {
          throw new Error(`Erro na requisição: ${response.message}`);
        }

      } catch (err) {
        setError(err.message); // Armazena o erro no estado 'error' caso a requisição falhe
        setContratos((prevContratos) => [...prevContratos, contratoParaExcluir]);
      }
    };

    fetchData(); // Chama a função para realizar a requisição

  }

  return (
    <div>
      {error && <p>Error: {error}</p>}
      <h1 id="Titulo">Contratos</h1>
      <div className="table-container">
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Nome do(a) aniversariante</th>
              <th>Data e hora</th>
              <th>Visualizar</th>
              <th>Excluir</th>
            </tr>
          </thead>
          <tbody>

            {contratos && contratos.length > 0 ? (
              contratos.map((element, index) => (
                <tr key={index}>
                  <td>{contratos.length - index}</td>
                  <td>{element.dados_evento?.nomeAniversariante || null}</td>
                  <td>{element.data_formatada}</td>
                  <td>
                    <i
                      className="bi bi-file-earmark-fill"
                      onClick={() => visualizarPDF(index)}
                      style={{ cursor: "pointer" }}
                      ></i>
                  </td>
                  <td>
                    <i
                      className="bi bi-trash"
                      onClick={() => excluir(index)}
                      style={{ cursor: "pointer" }}
                    ></i>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" style={{ textAlign: "center" }}>Nenhum contrato foi encontrado</td>
              </tr>
            )}

          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default Contratos;
