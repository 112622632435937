import React, { useState } from 'react';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Logo from '../imgs/uni-atende.png';
import LogoPepes from '../imgs/logo-pepes-park-buffet.png';

function Cabecalho({ dados, atualizarContratos, dataCW, valoresParcelas, formasPagamentoParcelas, datasParcelas, setDadosPagamento }) {
	const [statusBotao, setStatusBotao] = useState(false);
	const [botaoTexto, setBotaoTexto] = useState("Guardar dados");
	const [error, setError] = useState(null);

	const salvarDetalhesParcelas = () => {
		// Cria um array com os detalhes das parcelas, usando os dados recebidos.
		const valores = valoresParcelas.map((valor, index) => {
			const valorPagamento = valoresParcelas[index];
			const formaPagamento = formasPagamentoParcelas[index];
			const datasPagamento = datasParcelas[index];

			return {
				parcela: index + 1,
				valorParcela: valorPagamento,
				formaPagamentoParcela: formaPagamento,
				dataPagamentoParcela: datasPagamento
			};
		});

		// Atualiza o estado com os dados das parcelas
		setDadosPagamento(prev => ({
			...prev,
			detalhesParcelas: valores
		}));
	};

	const salvar = () => {
		setStatusBotao(true);
		setBotaoTexto("Salvando...");

		// Atualiza os detalhes das parcelas
		salvarDetalhesParcelas();

		// Envia os dados para a API de salvar contrato
		fetch('https://enginewebhook.w29.unigate.com.br/webhook/salvar-contrato', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				dados,
				contact_id: dataCW.data.contact.id,
				conversation_id: dataCW.data.conversation.id
			})
		})
			.then(response => {
				if (!response.ok) {
					throw new Error(`Erro na requisição: ${response.status}`);
				}
				setBotaoTexto("Salvou!");
				setTimeout(() => {
					setBotaoTexto("Guardar dados");
					setStatusBotao(false);
				}, 2000);
			})
			.catch(err => {
				setError('Erro ao salvar: ' + err.message);
				setStatusBotao(false);
				setBotaoTexto("Guardar dados");
			});
	};

	const criar = () => {
		setStatusBotao(true);

		// Atualiza os detalhes das parcelas
		salvarDetalhesParcelas();

		// Envia os dados para a API de criar contrato
		fetch('https://enginewebhook.w29.unigate.com.br/webhook/criar-contrato', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				dados,
				contact_id: dataCW.data.contact.id,
				conversation_id: dataCW.data.conversation.id
			})
		})
			.then(response => {
				if (!response.ok) {
					throw new Error(`Erro na requisição: ${response.status}`);
				}
				atualizarContratos(); // Atualiza os contratos na tela
				setStatusBotao(false);
			})
			.catch(err => {
				setError('Erro ao criar: ' + err.message);
				setStatusBotao(false);
			});
	};

	return (
		<div>
			<div id="Marca">
				<div>
					<Image id="Logo" src={LogoPepes} />
				</div>
				<div>
					<h1 id="PepeTitulo" style={{ color: '#02B890', fontWeight: 'bold' }}>
						Pepe's Park Buffet
					</h1>
				</div>
			</div>

			<div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px', marginBottom: '10px' }}>
				{error && <p>Error: {error}</p>}
				<div id="caixaLogoUni">
					<Image id="LogoUni" src={Logo} />
				</div>

				<div className="BtnContrato">
					<Button
						variant="secondary"
						disabled={statusBotao}
						className="custom-btn"
						onClick={salvar} // Chama salvar diretamente
					>
						{botaoTexto} <i className="bi bi-floppy"></i>
					</Button>
				</div>

				<div className="BtnContrato">
					<Button
						variant="secondary"
						disabled={statusBotao}
						className="custom-btn"
						onClick={criar} // Chama criar diretamente
					>
						Criar contrato <i className="bi bi-file-earmark-plus"></i>
					</Button>
				</div>

				<div className="BtnContrato" style={{ marginTop: "10px" }}>
					<h5>Vendedor(a): {dataCW.data.currentAgent.name}</h5>
				</div>

			</div>
		</div>
	);
}

export default Cabecalho;
