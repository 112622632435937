import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import React, { useState } from 'react';

function DadosPagamento({ dadosPagamento, setDadosPagamento }) {

  const [formasSelecionadas, setFormasSelecionadas] = useState([{ forma: '' }]);

  const formasPagamentoOpcoes = [
    { value: "Pix", label: "Pix" },
    { value: "Cartão de Débito", label: "Cartão de débito" },
    { value: "credito", label: "Cartão de crédito" },
    { value: "boleto", label: "Boleto" }
  ];

  const excluirFormaPagamento = () => {
    if (formasSelecionadas.length > 1) {
      const novasFormas = formasSelecionadas.slice(0, -1);
      setFormasSelecionadas(novasFormas);
    }
  };

  const handleFormaPagamentoChange = (e, index) => {
    const { value } = e.target;
    const novasFormas = [...formasSelecionadas];
    novasFormas[index].forma = value;
    setFormasSelecionadas(novasFormas);

    if (value) {
      e.target.options[0].style.display = "none";
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDadosPagamento({ ...dadosPagamento, [name]: value });

    if (value) {
      e.target.options[0].style.display = "none";
    }
  };

  const adicionarFormaPagamento = () => {
    if (formasSelecionadas.length < 2) {
      setFormasSelecionadas([...formasSelecionadas, { forma: '' }]);
    }
  };

  const getOpcoesFiltradas = (index) => {
    const formasEscolhidas = formasSelecionadas.map(f => f.forma);
    return formasPagamentoOpcoes.filter(
      opcao => !formasEscolhidas.includes(opcao.value) || opcao.value === formasSelecionadas[index].forma
    );
  };

  return (
    <div className='container'>
      <h1 id="Titulo">Dados do Pagamento</h1>
      <Form id="Form">
        <div>
          <div className="LinhaForm">
            <Form.Group style={{ width: "100%" }} className="CamposPagamento">
              <FloatingLabel controlId="floatingValor" label="Valor">
                <Form.Control
                  type="number"
                  placeholder="Valor"
                  value={dadosPagamento.valor}
                  name="valor"
                  onChange={handleInputChange}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group style={{ width: "100%" }} className="CamposPagamento">
              <FloatingLabel label="Parcelas">
                <Form.Select
                  aria-label="Parcelas"
                  value={dadosPagamento.parcelas}
                  name="parcelas"
                  onChange={handleInputChange}
                >
                  <option value="">Selecione a quantidade de parcelas</option>
                  {[...Array(12).keys()].map(i => (
                    <option key={i + 1} value={i + 1}>{i + 1}x</option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>

            <Form.Group style={{ width: "100%" }} className="CamposPagamento">
              <FloatingLabel controlId="floatingDataPagamento" label="Data de pagamento">
                <Form.Control
                  type="date"
                  placeholder="Data de pagamento"
                  value={dadosPagamento.data}
                  name="data"
                  onChange={handleInputChange}
                />
              </FloatingLabel>
            </Form.Group>
          </div>

          {formasSelecionadas.map((forma, index) => (
            <Form.Group key={index} className="CamposPagamento">
              <FloatingLabel label="Forma de pagamento">
                <Form.Select
                  aria-label="Forma de pagamento"
                  value={forma.forma}
                  onChange={(e) => handleFormaPagamentoChange(e, index)}
                >
                  <option value="">Forma de pagamento</option>
                  {getOpcoesFiltradas(index).map(opcao => (
                    <option key={opcao.value} value={opcao.value}>{opcao.label}</option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          ))}

          {formasSelecionadas.length < 2 ? (
            <Button
              style={{ marginLeft: "7px" }}
              className='custom-btn-pag'
              onClick={adicionarFormaPagamento}
            >
              <i className="bi bi-plus-lg"></i> Adicionar forma de pagamento
            </Button>
          ) : (
            <Button
              style={{ marginLeft: "7px" }}
              className='custom-btn-pag'
              onClick={excluirFormaPagamento}
            >
              <i className="bi bi-trash"></i> Excluir forma de pagamento
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
}

export default DadosPagamento;
